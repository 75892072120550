<template>

    <div class="sports-links" style="width: 50%">
        <router-link tag="a" to="/tearm1" class="link" active-class="active_sports_link">이용약관</router-link>
        <router-link tag="span" to="" class="link fg">|</router-link>
        <router-link tag="a" to="/tearm2" class="link" active-class="active_sports_link">운영정책</router-link>
        <router-link tag="span" to="" class="link fg">|</router-link>
        <router-link tag="a" to="/tearm3" class="link" active-class="active_sports_link">개인정보처리방침</router-link>
        <router-link tag="span" to="" class="link fg">|</router-link>
        <router-link tag="a" to="/tearm4" class="link" active-class="active_sports_link">이메일무단수집거부</router-link>
        <router-link tag="span" to="" class="link fg">|</router-link>
        <router-link tag="a" to="/tearm5" class="link" active-class="active_sports_link">제휴문의</router-link>
    </div>
</template>

<script>
    import sportsConst from "../../common/sportsConst";
    import {RECEIVE_DISPLAY_POSTION} from "../../store/mutation-types";

    export default {
        name: "ServiceLinksComp",
        props: {
        },
        data() {
            return {
                sportsConst,
            }
        },
        methods: {

        },
    }
</script>

<style scoped>
    @media screen and (max-width: 1024px) {
        .sports-links{
            flex-wrap: wrap!important;
        }
        .sports-links .fg{
            display: none;
        }
        .sports-links link{
            width: 49%!important;
        }
    }
</style>